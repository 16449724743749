import Vue from 'vue';
import Vuex from 'vuex';
import VueCookies from 'vue-cookies';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		user: {},
		streamers: [],
		isLoggedIn: false,
		isSuperUser: false,
		error_types: [
			'AuthTokenError', 
			'Forbidden', 
			'TokenExpired', 
			'JsonWebTokenError', 
			'TokenExpiredError', 
			'UserError',
			'invalidAccessToken',
		],
		base_url: process.env.VUE_APP_ROOT_API,
		redirect_uri: process.env.VUE_APP_REDIRECT_URI,
		app_name: process.env.VUE_APP_NAME,
		alert: true,
		userLoading: false
	},

	mutations: {
		SET_USER_INFO(state, user) {
			state.user = user;
		}
	},

	actions: {
		async fetchUserInfo({ commit }) {
			this.state.userLoading = true;
			try {
				const request = await fetch(this.state.base_url + '/user', {
					method: 'GET',
					headers: {
						"Authorization" : VueCookies.get('guardian_key_session'),
					}
				});

				if (request.ok) {
					const res = await request.json();

					if (res.user) commit('SET_USER_INFO', res.user);
					else throw res.error;
				}
			} 
			catch (error) {
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: error === 'Failed to fetch' ? 'There was a server error. Try later' : error 
				});
			}
			finally {
				this.state.userLoading = false;
			}
		}
	}
});