<template>
	<v-container fluid class="px-0 pt-0">
		<div class="mgn_video" oncontextmenu="return false;">
			<video id="mgn_vid" class="mgn_video" :src="require('../assets/background_video.mp4')" autoplay loop :muted="mute"></video>
		</div>

		<div class="mgn_vid_sound pa-8">
			<v-btn elevation="0" fab color="pink" @click="toggleMute()">
				<v-icon v-if="mute">mdi-volume-off</v-icon>
				<v-icon v-else>mdi-volume-high</v-icon>
			</v-btn>
		</div>

		<v-row class="text-center ma-0 mgn_login" align="center" justify="center">
			<v-col cols="12" sm="6" md="4" lg="3" xl="3" class="rounded-xl pa-8 mgn_header">
				<v-img :src="require(`../assets/${$imagePath}/full.png`)" class="my-2" contain height="300" transition="fade-transition"/>
				<!-- <h1 class="mgn_head font-weight-bold">StreamBoost</h1> -->
				<h2 class="mb-4 font-weight-bold">Collect Points! Earn Rewards!</h2>

				<v-btn 
					x-large
					class="ma-2" 
					elevation="0" 
					color="purple darken-5" 
					:href="twitch.url"
					:loading="loadingLoginBtn" 
					:disabled="loadingLoginBtn" 
				>
					Login with Twitch
					<v-icon class="ml-1">mdi-twitch</v-icon>
				</v-btn>

				<!-- <v-btn 
					class="ma-2" 
					elevation="0"
					x-large
					color="primary" 
					:loading="loadingLoginBtn" 
					:disabled="loadingLoginBtn" 
					:href="Trovo_AUTH"
				>
					Login with Trovo
					<v-img :src="require('../assets/trovo.png')" width="22" class="ml-2" transition="fade-transition"></v-img>
				</v-btn> -->

			</v-col>
		</v-row>

		<v-row class="ma-0" align="center" justify="start">
			<v-col sm="12" md="6" lg="6" xl="6" cols="12" class="pa-0">
				<v-img 
					class="shrink" 
					:src="require(`../assets/${$imagePath}/main.png`)" 
					transition="fade-transition"
				/>
			</v-col>

			<v-col sm="12" md="6" lg="6" xl="6" cols="12" class="pa-15">
				<h2>Καλώς ήρθατε στο StreamBoost!</h2>
				<p class="my-4">Το StreamBoost είναι ένα project που ο σκοπός του είναι οι streamers που παίρνουν μέρος σε αυτό, να επιβραβεύσουν τους viewers τους μέσα από ένα point system στο οποίο μπορούν να συμμετέχουν όλοι συνεχίζοντας να κάνουν αυτό που ήδη έκαναν, να βλέπουν τα streams , να είναι ενεργοί και να περνάνε καλά! Σε αρχικό στάδιο, τα points που μαζεύονται μπορούν να εξαργυρωθούν για in-game rewards στον GTA server της επιλογής σας!</p>
			</v-col>
		</v-row>

		<v-card class="rounded-0 px-15" elevation="0">
			<v-row align="center" justify="center">
				<v-col sm="12" md="6" lg="6" xl="6" cols="12">
					<h2>F.A.Q.</h2>
					<div v-for="(qa, index) in faq" :key="index" class="my-6">
						<p v-html="'Q: ' + qa.q" class="ma-0"></p>
						<p v-html="'A: ' + qa.a" class="ma-0"></p>
					</div>
				</v-col>

				<v-col sm="12" md="6" lg="6" xl="6" cols="12" align="center">
					<v-img width="600" :src="require(`../assets/${$imagePath}/full.png`)" transition="fade-transition"></v-img>
				</v-col>
			</v-row>
		</v-card>
	</v-container>
</template>

<script>
export default {
	props: ['loadingLoginBtn'],

	data() {
		return {
			twitch: {
				url: 'https://id.twitch.tv/oauth2/authorize',
				response_type: 'code',
				client_id: process.env.VUE_APP_TWITCHCLIENT_ID,
				redirect_uri: process.env.VUE_APP_REDIRECT_URI,
				scopes: [
					'chat:read',
					'chat:edit',
					'channel:read:subscriptions',
					'channel:manage:moderators',
					'channel:manage:broadcast',
					'channel:edit:commercial',
					'channel:read:vips',
					'channel:manage:vips',
					'moderator:read:chatters',
					'moderator:read:followers',
					'moderator:manage:chat_messages',
					'moderator:manage:banned_users',
					'moderator:manage:announcements',
					'bits:read',
				]
			},
			trovo: {
				url: 'https://open.trovo.live/page/login.html',
				response_type: 'token',
				client_id: process.env.VUE_APP_CLIENT_ID,
				redirect_uri: process.env.VUE_APP_REDIRECT_URI,
				scopes: [
					'user_details_self',
					'chat_send_self',
					'send_to_my_channel',
					'manage_messages',
				]
			},
			mute: true,
			faq: [
				{
					q: 'Πώς μαζεύω πόντους;',
					a: 'Βλέποντας τα streams και γράφοντας στο chat!'
				},
				{
					q: 'Πού μπορώ να δω τους πόντους μου;',
					a: 'Με !points στο chat ή στο <a href="/dashboard">Dashboard</a>'
				},
				{
					q: 'Μπορώ να δώσω τους πόντους ή τα rewards μου σε άλλον;',
					a: 'Όχι, τα points και τα rewards είναι personal.'
				},
				{
					q: 'Θα κάνουν reset οι πόντοι μου;',
					a: 'Ακόμα δεν είμαστε σίγουροι γι\'αυτό, καθώς μελετάμε το πως θα δουλεύει μελλοντικά το σύστημα, αλλά υπάρχει μεγάλη πιθανότητα να γίνεται reset κάθε 30 ή 60 ημέρες.'
				}
			]
		}
	},

	computed: {
		Trovo_AUTH() {
			return 'https://open.trovo.live/page/login.html?client_id=' + process.env.VUE_APP_CLIENT_ID + '&response_type=code&scope=user_details_self&redirect_uri=' + window.location.origin;
		}
	},

	mounted() {
		this.twitch.url = `${this.twitch.url}?response_type=${this.twitch.response_type}&client_id=${this.twitch.client_id}&redirect_uri=${this.twitch.redirect_uri}&scope=`;

		this.twitch.scopes.forEach(scope => {
			this.twitch.url = this.twitch.url + scope + '+'
		});

		this.twitch.url = this.twitch.url.slice(0, -1);

		this.trovo.url = `${this.trovo.url}?client_id=${this.trovo.client_id}&response_type=${this.twitch.response_type}&redirect_uri=${this.trovo.redirect_uri}&scope=`;

		this.trovo.scopes.forEach(scope => {
			this.trovo.url = this.trovo.url + scope + '+'
		});

		this.trovo.url = this.trovo.url.slice(0, -1);
	},

	methods: {
		toggleMute() {
			this.mute = !this.mute
		}
	}
}
</script>

<style scoped>
.mgn_head {
	font-size: 3.4rem;
}

.mgn_login {
	width: 100vw;
	height: 100vh;
}

.mgn_video {
	object-fit: cover;
	width: 100vw;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
}

.mgn_vid_sound {
	z-index: 100;
	position: fixed;
	bottom: 0;
	right: 0;
}
</style>